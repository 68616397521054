<template>
    <div>
        
    </div>
</template>

<script>
export default {
    name: 'StoreWebTime',

    components: {  },

    directives: {  },

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>

</style>